<template>
  <div class="form-container">
    <div class="align-right">
      <!-- *는 필수입력 항목입니다. -->
      <span class="required-mark">*</span>{{ $t('content.networking.CompanyInfo.txt53') }}
    </div>

    <ValidationObserver v-slot="{ handleSubmit, invalid }">
      <form @submit.prevent="handleSubmit(doNext)">
        <fieldset>
          <legend>{{ $t("signUp.title.agreement") }}</legend>

          <!-- 전체 동의 -->
          <div class="check-all">
            <div>
              <BaseCheckbox
                id="allAgree"
                v-model="allAgreed"
                @change="onAllAgreedChange"
              >{{ $t('content.signup.Agreement.txt01') }}</BaseCheckbox>
            </div>
          </div>
          <!-- // 전체 동의 -->

          <!-- 회원 가입 약관 (필수) -->
          <div>
            <!-- required : https://logaretm.github.io/vee-validate/guide/rules.html#required -->
            <ValidationProvider
              :name="$t('content.signup.Agreement.txt02')"
              :rules="{ required: { allowFalse: false } }"
            >
              <BaseCheckbox
                id="agree1"
                v-model="joinAgreed"
                required
              >{{ $t('content.signup.Agreement.txt02') }}</BaseCheckbox>
            </ValidationProvider>

            <div class="terms-container">
              <TermsText />
            </div>
          </div>
          <!-- // 회원 가입 약관 (필수) -->

          <!-- 개인정보 수집 및 이용 (필수) -->
          <div>
            <!-- required : https://logaretm.github.io/vee-validate/guide/rules.html#required -->
            <ValidationProvider
              :name="$t('content.signup.Agreement.txt06')"
              :rules="{ required: { allowFalse: false } }"
            >
              <BaseCheckbox
                id="agree2"
                v-model="privateMandatoryAgreed"
                required
              >{{ $t('content.signup.Agreement.txt06') }}</BaseCheckbox>
            </ValidationProvider>

            <div class="terms-container">
              <div class="view-box">
                <dl class="terms">
                  <dd>{{$t('content.signup.popup.PersonalInfoUse.txt02')}}<br />
                  {{$t('content.signup.popup.PersonalInfoUse.txt03')}}<br />
                  </dd>
                  <dt>{{$t('content.signup.popup.PersonalInfo.txt04')}}</dt>
                  <dd>{{$t('content.signup.popup.PersonalInfoUse.txt05')}}<br />
                  {{$t('content.signup.popup.PersonalInfoUse.txt06')}}<br />
                  {{$t('content.signup.popup.PersonalInfoUse.txt07')}}<br />
                  {{$t('content.signup.popup.PersonalInfoUse.txt08')}}<br />
                  {{$t('content.signup.popup.PersonalInfoUse.txt09')}}<br />
                  <!-- {{$t('content.signup.popup.PersonalInfoUse.txt10')}}<br /> -->
                  {{$t('content.signup.popup.PersonalInfoUse.txt11')}}<br />
                  {{$t('content.signup.popup.PersonalInfoUse.txt12')}}<br />
                  {{$t('content.signup.popup.PersonalInfoUse.txt13')}}<br />
                  {{$t('content.signup.popup.PersonalInfoUse.txt14')}}<br />
                  {{$t('content.signup.popup.PersonalInfoUse.txt15')}}<br />
                  <!-- {{$t('content.signup.popup.PersonalInfoUse.txt16')}}<br /> -->
                  {{$t('content.signup.popup.PersonalInfoUse.txt17')}}<br />
                  {{$t('content.signup.popup.PersonalInfoUse.txt18')}}<br />
                  </dd>
                  <dt> {{$t('content.signup.popup.PersonalInfoUse.txt19')}}</dt>
                  <dd>{{$t('content.signup.popup.PersonalInfoUse.txt20')}}<br />
                  <!-- {{$t('content.signup.popup.PersonalInfoUse.txt21')}}<br /> -->
                  {{$t('content.signup.popup.PersonalInfoUse.txt22')}}<br />
                  {{$t('content.signup.popup.PersonalInfoUse.txt23')}}<br />
                  <!-- {{$t('content.signup.popup.PersonalInfoUse.txt24')}}<br /> -->
                  {{$t('content.signup.popup.PersonalInfoUse.txt25')}}<br />
                  {{$t('content.signup.popup.PersonalInfoUse.txt26')}}<br />
                  {{$t('content.signup.popup.PersonalInfoUse.txt27')}}<br />
                  </dd>
                </dl>
              </div>
            </div>
          </div>
          <!-- // 개인정보 처리방침 (필수) -->
          <div>
            <ValidationProvider
              :name="$t('content.signup.Agreement.txt07')"
              :rules="{ required: { allowFalse: false } }"
            >
              <BaseCheckbox
                id="agree3"
                v-model="privateOptionalAgreed"
                required
              >{{ $t('content.signup.Agreement.txt07') }}</BaseCheckbox>
            </ValidationProvider>

            <div class="terms-container">
              <div class="view-box">
                <dl class="terms">
                  <dd>{{$t('content.signup.popup.PersonalInfoUse.txt02')}}<br />
                  {{$t('content.signup.popup.PersonalInfoUse.txt03')}}<br />
                  </dd>
                  <dt>{{$t('content.signup.popup.PersonalInfo.txt04')}}</dt>
                  <dd>{{$t('content.signup.popup.PersonalInfoUse.txt05')}}<br />
                  {{$t('content.signup.popup.PersonalInfoUse.txt06')}}<br />
                  {{$t('content.signup.popup.PersonalInfoUse.txt07')}}<br />
                  {{$t('content.signup.popup.PersonalInfoUse.txt08')}}<br />
                  {{$t('content.signup.popup.PersonalInfoUse.txt09')}}<br />
                  <!-- {{$t('content.signup.popup.PersonalInfoUse.txt10')}}<br /> -->
                  {{$t('content.signup.popup.PersonalInfoUse.txt11')}}<br />
                  {{$t('content.signup.popup.PersonalInfoUse.txt12')}}<br />
                  {{$t('content.signup.popup.PersonalInfoUse.txt13')}}<br />
                  {{$t('content.signup.popup.PersonalInfoUse.txt14')}}<br />
                  {{$t('content.signup.popup.PersonalInfoUse.txt15')}}<br />
                  <!-- {{$t('content.signup.popup.PersonalInfoUse.txt16')}}<br /> -->
                  {{$t('content.signup.popup.PersonalInfoUse.txt17')}}<br />
                  {{$t('content.signup.popup.PersonalInfoUse.txt18')}}<br />
                  </dd>
                  <dt> {{$t('content.signup.popup.PersonalInfoUse.txt19')}}</dt>
                  <dd>{{$t('content.signup.popup.PersonalInfoUse.txt20')}}<br />
                  <!-- {{$t('content.signup.popup.PersonalInfoUse.txt21')}}<br /> -->
                  {{$t('content.signup.popup.PersonalInfoUse.txt22')}}<br />
                  {{$t('content.signup.popup.PersonalInfoUse.txt23')}}<br />
                  <!-- {{$t('content.signup.popup.PersonalInfoUse.txt24')}}<br /> -->
                  {{$t('content.signup.popup.PersonalInfoUse.txt25')}}<br />
                  {{$t('content.signup.popup.PersonalInfoUse.txt26')}}<br />
                  {{$t('content.signup.popup.PersonalInfoUse.txt27')}}<br />
                  </dd>
                  <dt> {{$t('content.signup.popup.PersonalInfoUse.txt28')}}</dt>
                  <dd>{{$t('content.signup.popup.PersonalInfoUse.txt29')}}<br />
                  {{$t('content.signup.popup.PersonalInfoUse.txt30')}}<br />
                  <!-- {{$t('content.signup.popup.PersonalInfoUse.txt31')}}<br /> -->
                  {{$t('content.signup.popup.PersonalInfoUse.txt32')}}<br />
                  <!-- {{$t('content.signup.popup.PersonalInfoUse.txt33')}}<br /> -->
                  {{$t('content.signup.popup.PersonalInfoUse.txt34')}}<br />
                  {{$t('content.signup.popup.PersonalInfoUse.txt35')}}<br />
                  </dd>
                  <dt> {{$t('content.signup.popup.PersonalInfoUse.txt36')}}</dt>
                  <dd>{{$t('content.signup.popup.PersonalInfoUse.txt37')}}<br />
                  {{$t('content.signup.popup.PersonalInfoUse.txt38')}}<br />
                  <!-- {{$t('content.signup.popup.PersonalInfoUse.txt42')}}<br /> -->
                  </dd>
                  <dt>
                    <table>
                      <colgroup>
                        <col width="50%">
                        <col width="25%">
                        <col width="25%">
                      </colgroup>
                      <tr>
                        <th>{{$t('content.signup.popup.Management.head01')}}</th>
                        <th>{{$t('content.signup.popup.Management.head02')}}</th>
                        <th>{{$t('content.signup.popup.Management.head03')}}</th>
                      </tr>
                      <tr>
                        <td>{{$t('content.signup.popup.Management.txt04')}}</td>
                        <td>{{$t('content.signup.popup.Management.txt05')}}</td>
                        <td>{{$t('content.signup.popup.Management.txt06')}}</td>
                      </tr>
                    </table>
                  </dt>
                  <dt> {{$t('content.signup.popup.PersonalInfoUse.txt43')}}</dt>
                  <dd>{{$t('content.signup.popup.PersonalInfoUse.txt44')}}<br />
                  <!-- {{$t('content.signup.popup.PersonalInfoUse.txt45')}}<br /> -->
                  {{$t('content.signup.popup.PersonalInfoUse.txt46')}}<br />
                  {{$t('content.signup.popup.PersonalInfoUse.txt47')}}<br />
                  <!-- {{$t('content.signup.popup.PersonalInfoUse.txt48')}}<br /> -->
                  {{$t('content.signup.popup.PersonalInfoUse.txt49')}}<br />
                  {{$t('content.signup.popup.PersonalInfoUse.txt50')}}<br />
                  {{$t('content.signup.popup.PersonalInfoUse.txt51')}}<br />
                  </dd>
                  <dt> {{$t('content.signup.popup.PersonalInfoUse.txt52')}}</dt>
                  <dd>{{$t('content.signup.popup.PersonalInfoUse.txt53')}}<br />
                  {{$t('content.signup.popup.PersonalInfoUse.txt54')}}<br />
                  {{$t('content.signup.popup.PersonalInfoUse.txt55')}}<br />
                  {{$t('content.signup.popup.PersonalInfoUse.txt56')}}<br />
                  {{$t('content.signup.popup.PersonalInfoUse.txt57')}}<br />
                  {{$t('content.signup.popup.PersonalInfoUse.txt58')}}<br />
                  <!-- {{$t('content.signup.popup.PersonalInfoUse.txt59')}}<br /> -->
                  {{$t('content.signup.popup.PersonalInfoUse.txt60')}}<br />
                  {{$t('content.signup.popup.PersonalInfoUse.txt61')}}<br />
                  {{$t('content.signup.popup.PersonalInfoUse.txt62')}}<br />
                  {{$t('content.signup.popup.PersonalInfoUse.txt63')}}<br />
                  {{$t('content.signup.popup.PersonalInfoUse.txt64')}}<br />
                  {{$t('content.signup.popup.PersonalInfoUse.txt65')}}<br />
                  <!-- {{$t('content.signup.popup.PersonalInfoUse.txt66')}}<br /> -->
                  {{$t('content.signup.popup.PersonalInfoUse.txt67')}}<br />
                  {{$t('content.signup.popup.PersonalInfoUse.txt68')}}<br />
                  {{$t('content.signup.popup.PersonalInfoUse.txt69')}}<br />
                  {{$t('content.signup.popup.PersonalInfoUse.txt70')}}<br />
                  {{$t('content.signup.popup.PersonalInfoUse.txt71')}}<br />
                  {{$t('content.signup.popup.PersonalInfoUse.txt72')}}<br />
                  <!-- {{$t('content.signup.popup.PersonalInfoUse.txt73')}}<br /> -->
                  {{$t('content.signup.popup.PersonalInfoUse.txt74')}}<br />
                  {{$t('content.signup.popup.PersonalInfoUse.txt75')}}<br />
                  </dd>
                  <dt> {{$t('content.signup.popup.PersonalInfoUse.txt76')}}</dt>
                  <dd>{{$t('content.signup.popup.PersonalInfoUse.txt77')}}<br />
                  <!-- {{$t('content.signup.popup.PersonalInfoUse.txt78')}}<br /> -->
                  {{$t('content.signup.popup.PersonalInfoUse.txt79')}}<br />
                  {{$t('content.signup.popup.PersonalInfoUse.txt80')}}<br />
                  {{$t('content.signup.popup.PersonalInfoUse.txt81')}}<br />
                  {{$t('content.signup.popup.PersonalInfoUse.txt82')}}<br />
                  {{$t('content.signup.popup.PersonalInfoUse.txt83')}}<br />
                  <!-- {{$t('content.signup.popup.PersonalInfoUse.txt84')}}<br /> -->
                  {{$t('content.signup.popup.PersonalInfoUse.txt85')}}<br />
                  {{$t('content.signup.popup.PersonalInfoUse.txt86')}}<br />
                  {{$t('content.signup.popup.PersonalInfoUse.txt87')}}<br />
                  {{$t('content.signup.popup.PersonalInfoUse.txt88')}}<br />
                  </dd>
                  <dt> {{$t('content.signup.popup.PersonalInfoUse.txt89')}}</dt>
                  <dd>{{$t('content.signup.popup.PersonalInfoUse.txt90')}}<br />
                  <!-- {{$t('content.signup.popup.PersonalInfoUse.txt91')}}<br /> -->
                  {{$t('content.signup.popup.PersonalInfoUse.txt92')}}<br />
                  {{$t('content.signup.popup.PersonalInfoUse.txt93')}}<br />
                  {{$t('content.signup.popup.PersonalInfoUse.txt94')}}<br />
                  {{$t('content.signup.popup.PersonalInfoUse.txt95')}}<br />
                  {{$t('content.signup.popup.PersonalInfoUse.txt96')}}<br />
                  <!-- {{$t('content.signup.popup.PersonalInfoUse.txt97')}}<br /> -->
                  {{$t('content.signup.popup.PersonalInfoUse.txt100')}}<br />
                  {{$t('content.signup.popup.PersonalInfoUse.txt101')}}<br />
                  {{$t('content.signup.popup.PersonalInfoUse.txt102')}}<br />
                  {{$t('content.signup.popup.PersonalInfoUse.txt103')}}<br />
                  <!-- {{$t('content.signup.popup.PersonalInfoUse.txt104')}}<br /> -->
                  {{$t('content.signup.popup.PersonalInfoUse.txt105')}}<br />
                  {{$t('content.signup.popup.PersonalInfoUse.txt106')}}<br />
                  <br />
                  </dd>
                </dl>
              </div>
            </div>
          </div>
          <!-- // 개인정보 수집 및 이용 (선택) -->

          <!-- 개인정보 취급업무 위탁 (필수) -->
          <div>
            <!-- required : https://logaretm.github.io/vee-validate/guide/rules.html#required -->
            <ValidationProvider
              :name="$t('content.signup.Agreement.txt09')"
              :rules="{ required: { allowFalse: false } }"
            >
              <BaseCheckbox
                id="agree5"
                v-model="managementAgreed"
                required
              >{{ $t('content.signup.Agreement.txt09') }}</BaseCheckbox>
            </ValidationProvider>
            <div class="terms-container">
              <div class="view-box">
                <dl class="terms">
                  <dt>{{$t('content.signup.popup.Management.txt01')}}</dt>
                  <dd>{{$t('content.signup.popup.Management.txt02')}}</dd>
                  <dd>{{$t('content.signup.popup.Management.txt03')}}<br/>
                  </dd>
                  <dt>
                    <table>
                      <colgroup>
                        <col width="50%">
                        <col width="25%">
                        <col width="25%">
                      </colgroup>
                      <tr>
                        <th>{{$t('content.signup.popup.Management.head01')}}</th>
                        <th>{{$t('content.signup.popup.Management.head02')}}</th>
                        <th>{{$t('content.signup.popup.Management.head03')}}</th>
                      </tr>
                      <tr>
                        <td>{{$t('content.signup.popup.Management.txt04')}}</td>
                        <td>{{$t('content.signup.popup.Management.txt05')}}</td>
                        <td>{{$t('content.signup.popup.Management.txt06')}}</td>
                      </tr>
                    </table>
                  </dt>
                </dl>
              </div>
            </div>
          </div>
          <!-- // 개인정보 취급업무 위탁 (필수) -->

          <!-- 14세 이상 (필수) -->
          <div>
            <!-- required : https://logaretm.github.io/vee-validate/guide/rules.html#required -->
            <ValidationProvider
              :name="$t('content.signup.Agreement.txt08')"
              :rules="{ required: { allowFalse: false } }"
            >
              <BaseCheckbox
                id="agree4"
                v-model="ageAgreed"
                required
                style="display: none;"
              >{{ $t('content.signup.Agreement.txt08') }}</BaseCheckbox>
              <BaseLabelElement
                type="inline"
                :label="$t('content.signup.Agreement.txt08')"
                style="font-size: 14px; font-weight: 500; line-height: 1.38; margin-right: 40px;
                vertical-align: middle;"
                required
              />
              <BaseRadioButton
                name="ageAgreed"
                v-model="radioAgeAgreed"
                value="true"
                @change="alertAgeAgree"
                style="margin-right: 21px; vertical-align: middle;"
              >동의</BaseRadioButton>
              <!-- 팀 -->
              <BaseRadioButton
                name="ageAgreed"
                v-model="radioAgeAgreed"
                value="false"
                @change="alertAgeAgree"
                style="margin-right: 21px; vertical-align: middle;"
              >미동의</BaseRadioButton>
            </ValidationProvider>
            <div class="terms-container">
              <div class="view-box">
                <dl class="terms">
                  <dt>{{$t('content.signup.popup.AgeInfo.txt01')}}</dt>
                </dl>
              </div>
            </div>
          </div>
          <!-- // 14세 이상 (필수) -->
        </fieldset>

        <div class="btn-wrap">
          <BaseButton
            type="gray"
            @click="cancel"
          >{{$t('content.common.button.btn05')}}</BaseButton>
          <BaseButton
            nativeType="submit"
            :disabled="invalid"
          >{{$t('content.common.button.btn02')}}</BaseButton>
        </div>
      </form>
    </ValidationObserver>
  </div>
</template>

<script>
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import { mixin as mixinHelperUtils } from '@/common/helperUtils';
import BaseButton from '@/components/base/BaseButton.vue';
import BaseCheckbox from '@/components/base/BaseCheckbox.vue';
// import sha512 from 'sha512'; // http://cryptocoinjs.com/modules/crypto/sha512/
// import { APIs } from '@/common/portalApi';
import routeTypes from '@/router/routeTypes';
import TermsText from '@/views/legal/component/TermsText.vue';
import BaseLabelElement from '@/components/base/BaseLabelElement.vue';
import BaseRadioButton from '@/components/base/BaseRadioButton.vue';

export default {
  name: 'SignUpStep3',
  mixins: [mixinHelperUtils],
  components: {
    ValidationObserver, // https://logaretm.github.io/vee-validate/api/validation-observer.html
    ValidationProvider, // https://logaretm.github.io/vee-validate/api/validation-provider.html
    BaseButton,
    BaseCheckbox,
    TermsText,
    BaseLabelElement,
    BaseRadioButton,
  },

  props: {
    userInfo: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      allAgreed: false,
      joinAgreed: false,
      privateMandatoryAgreed: false,
      privateOptionalAgreed: false,
      dreamAgreed: false,
      ageAgreed: false,
      radioAgeAgreed: '',
      managementAgreed: false,
    };
  },

  mounted() {
    if (!this.userInfo) {
      this.cancel();
    }
  },

  watch: {
    joinAgreed(newVal) {
      this.allAgreed = newVal && this.privateMandatoryAgreed && this.privateOptionalAgreed
        && this.ageAgreed && this.managementAgreed;
    },
    privateMandatoryAgreed(newVal) {
      this.allAgreed = this.joinAgreed && newVal && this.privateOptionalAgreed
        && this.ageAgreed && this.managementAgreed;
    },
    privateOptionalAgreed(newVal) {
      this.allAgreed = this.joinAgreed && this.privateMandatoryAgreed && newVal
        && this.ageAgreed && this.managementAgreed;
    },
    ageAgreed(newVal) {
      this.allAgreed = this.joinAgreed && this.privateMandatoryAgreed && this.privateOptionalAgreed
        && newVal && this.managementAgreed;
    },
    managementAgreed(newVal) {
      this.allAgreed = this.joinAgreed && this.privateMandatoryAgreed && this.privateOptionalAgreed
        && this.ageAgreed && newVal;
    },
  },

  methods: {
    cancel() {
      this.$router.replace({
        name: routeTypes.ROUTE_NAME_SIGNUP,
      });
    },
    doNext() {
      this.portalApiClient({
        url: `/auth/sns/signup`,
        headers: {
          'Access-Control-Allow-Origin': '*',
          'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
        },
        data: {
          email: this.userInfo.email,
          accessToken: this.userInfo.access_token,
          codeRegisterType: this.userInfo.apply_path,
          name: this.userInfo.name,
          fingerPrint: this.userInfo.finger_print,
          isAdmin: false,
        },
      }).then(({ data }) => {
        if (data.result) {
          this.$router.replace({
            name: 'SignUpStep4',
            params: {
              email: this.userInfo.email,
              isSns: true,
            },
          });
        }
      });
    },

    onAllAgreedChange(value) {
      this.joinAgreed = value;
      this.privateMandatoryAgreed = value;
      this.privateOptionalAgreed = value;
      this.ageAgreed = value;
      this.radioAgeAgreed = `${value}`;
      this.managementAgreed = value;
    },

    alertAgeAgree(value) {
      if (value === 'true') {
        this.ageAgreed = true;
      } else {
        this.ageAgreed = false;
        this.showAlert(this.$t('content.signup.popup.AgeInfo.txt02'));
      }
    },
  },
};
</script>
